import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FaceBookIcon from "../images/about-facebook.svg"
import InstagramIcon from "../images/about-instagram.svg"
import AppStoreIcon from "../images/official-appstore-icon.svg"
import MobileSectionBg from "../images/about-mobile-bg.svg"

const ContactPage = () => (
	<Layout>
    <SEO title="Contact" />
    <section className="contact-hero">
      <div className="container">
        <div className="flex-row">
          <div className="col">
            <p>For the fastest answers check out our <a href="https://help.mealpreppro.com">FAQ</a> to find the answer you’re looking for.</p>
            <h1>We’d Love To Hear From You</h1>
          </div>
          <div className="col">
            <div className="background-image desktop-hidden">
              <img src={MobileSectionBg} alt="form-mobile-image" />
            </div>
            <form>
              <label>Your Email</label>
              <input type="email" name="email" required />
              <label>Subject</label>
              <input type="text" name="subject" required />
              <label>Message</label>
              <textarea name="message" rows="5" />
              <label>
                <input type="checkbox" name="happy" />
                I’m happy to receive email from MealPrepPro. The team will aim to respond to your query within 48 hours.
              </label>
              <button className="btn" type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <footer className="about-footer">
      <div className="container">
          <div className="flex-row menus">
            <a href="https://nibbleapps.com/terms">Terms of Service</a>
            <a href="https://nibbleapps.com/privacy">Privacy Policy</a>
            <a href="https://help.mealpreppro.com/">FAQ</a>
          </div>
          <div className="flex-row social">
            <a href="https://www.facebook.com/groups/mealpreppro" target="_blank"><img className="facebook" src={FaceBookIcon} alt="facebook" /></a>
            <a href="https://www.instagram.com/mealpreppro" target="_blank"><img className="instagram" src={InstagramIcon} alt="instagram" /></a>
          </div>
        </div>
        <div className="appstore">
          <a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
            <img src={AppStoreIcon} alt="appstore" />
          </a>
        </div>
    </footer>
  </Layout>
)

export default ContactPage
